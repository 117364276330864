//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DateEnumFilter from '@/components/EventList/DateEnumFilter.vue';
// import TagFilter from "@/components/EventList/TagFilter.vue";
import EventTable from '@/components/EventList/EventTable.vue';
import VenueEnumFilter from '@/components/EventList/VenueEnumFilter.vue';
import EventCalendar from '@/components/EventList/EventCalendar.vue';
import SanghaEventDetail from '@/components/SanghaEventDetail.vue';
import ExportEventsModalComponent from '@/components/Together/ExportEventsModal.vue';
import { eventFilter } from '@/pages/events.js';
import { Events, Venues, Tags, AttendanceTypes } from '@/services/api.service';
import { EventBus } from '@/services/event-bus';
import { getMonthNames, getDayNames } from '@/services/helpers.js';
import { i18n } from '@/services/i18n.init';
import * as moment from 'moment-timezone';

const TagFilter = () => import('@/components/EventList/TagFilter.vue');

const DEFAULT_ROWS_PER_PAGE = 15;
const defaultDateSpanOptions = [
  { label: i18n.t('events.my.filter.date_all'), value: 'all' },
  { label: i18n.t('events.my.filter.date_today'), value: 'today' },
  { label: i18n.t('events.my.filter.date_next_week'), value: 'next-week' },
  { label: i18n.t('events.my.filter.date_last_week'), value: 'last-week' },
  { label: i18n.t('events.my.filter.date_last_month'), value: 'last-month' },
  { label: i18n.t('events.my.filter.date_custom'), value: 'custom' },
];
const defaultPrivacyOptions = [];
const defaultVisibleTypeOptions = [
  // { label: i18n.t('events.my.filter.cancelled_any'), value: 'all'},
  {
    label: i18n.t('events.my.filter.cancelled_none').replace(' ', '&nbsp;'),
    value: 'only-active',
  },
  {
    label: i18n.t('events.my.filter.cancelled_only').replace(' ', '&nbsp;'),
    value: 'only-cancelled',
  },
];

let initialVenueFilter = [];
let privacyTypeAll = null;

let startupInterval = null; // used for url-fetching timeout for async requests

let myEventFilter = eventFilter();

const CALENDAR_LIMIT = 100;


export default {
  name: 'EventList',
  data() {
    return {
      pagination: {
        sortBy: 'dateFrom',
        descending: true,
        page: 1,
        rowsPerPage: DEFAULT_ROWS_PER_PAGE,
        hasNextPage: false,
        hasPrevPage: false,
        rowsNumber: 1,
      },
      columns: [
        {
          name: 'dateFrom',
          label: this.$i18n.t('events.my.header.date'),
          align: 'center',
          sortable: true,
        },
        {
          name: 'image',
          label: this.$i18n.t('events.my.header.image'),
          align: 'center',
        },
        {
          name: 'title',
          label: this.$i18n.t('events.my.header.title'),
          align: 'left',
          width:'180px',
          sortable: true,
        },
        {
          name: 'venue',
          label: this.$i18n.t('events.my.header.venue'),
          align: 'center',
          sortable: true,
        },
        {
          name: 'tags',
          label: this.$i18n.t('events.my.header.tags'),
          align: 'center',
          sortable: false,
        },
        {
          name: 'attendanceType',
          label: this.$i18n.t('events.my.header.privacy'),
          align: 'center',
          sortable: true,
        },
        {
          name: 'actions',
          label: this.$i18n.t('events.my.header.actions'),
          align: 'center',
        },
      ],
      listingType: null,
      dateFrom: null,
      dateTo: null,
      venueId: null,
      tagLanguage: [],
      tagTeaching: [],
      venueFilter: initialVenueFilter,
      enumVenue: null,
      enumDate: null,
      enumDateSelected: null,
      dateSpan: null,
      dateSpanOptions: defaultDateSpanOptions,
      order: 'desc',
      customDateFieldsVisible: false,
      privacyType: privacyTypeAll,
      privacyOptions: defaultPrivacyOptions,
      visibleTypeOptions: defaultVisibleTypeOptions,
      visibleType: null, // defaultVisibleTypeOptions[1],
      //onlyOfficial: null,

      eventList: [], // main list of events

      fetchedEventDetail: null, // additional info for preview (fetched from separate API call)
      eventDetail: null, // basic info for preview
      selected: [], // user-selected events via checkboxes
      searchQuery: null,
      inProgress: false, // loading spinner
      showEventDeleteModal: false, // delete modal
      showEventDetailModal: false, // detail modal
      showRecurringDelayWarning: false,
      lastUrl: null, // for save state - do not save duplicate

      show: (this.$route.query && this.$route.query.show ? this.$route.query.show : 'table'), // show 'calendar' or 'table'

      noSaveState: false, // should save current url to history state ?
      asyncRequestCounter: 0, // count of async requests on startup
      watchersEnabled: false, // should enable watchers (and search after them)?
      initialLoadingState: true,

      locale: {
        // locales for datepicker
        days: getDayNames(this.$i18n.locale),
        daysShort: getDayNames(this.$i18n.locale, 'short'),
        months: getMonthNames(this.$i18n.locale),
        monthsShort: getMonthNames(this.$i18n.locale, 'short'),
        firstDayOfWeek: 1, /// !!!! todo ?
      },

      menuIsShowing: false,

      showExportEventsModal: false,
      noProfileRetryTimeout: null,
    };
  },
  components: {
    SanghaEventDetail,
    VenueEnumFilter,
    DateEnumFilter,
    EventTable,
    EventCalendar,
    TagFilter,
    ExportEventsModalComponent,
  },
  methods: {
    getTagsFromUrl() {
      if (this.$route.query.tagTeaching || this.$route.query.tagLanguage) {
        this.asyncRequestCounter++;

        const teachingIds = this.$route.query.tagTeaching
          ? this.$route.query.tagTeaching.split(',')
          : [];
        const languageIds = this.$route.query.tagLanguage
          ? this.$route.query.tagLanguage.split(',')
          : [];

        Tags.list().then(
          (tagListRes) => {
            this.asyncRequestCounter--;
            if (
              !tagListRes ||
              !tagListRes.data ||
              !tagListRes.data.data ||
              !tagListRes.data.data.getTags
            )
              return;
            this.noSaveState = true;

            if (teachingIds.length) {
              this.tagTeaching = tagListRes.data.data.getTags.filter((i) =>
                teachingIds.includes(i.code)
              );
            } else {
              this.tagTeaching = [];
            }
            if (languageIds.length) {
              this.tagLanguage = tagListRes.data.data.getTags.filter((i) =>
                languageIds.includes(i.code)
              );
            } else {
              this.tagLanguage = [];
            }
          },
          () => {
            this.asyncRequestCounter--;
          }
        );
      } else {
        this.tagTeaching = [];
        this.tagLanguage = [];
      }
    },
    getVenueFromUrl() {
      if (this.$route.query.venueId && this.$route.query.venueId != 'null') {
        this.asyncRequestCounter++;
        Venues.detail(this.$route.query.venueId).then(
          (venue) => {
            this.noSaveState = true;
            this.venueId = {
              id: venue.data.data.getVenue.id,
              label: venue.data.data.getVenue.title,
            };
            this.asyncRequestCounter--;
          },
          () => {
            this.asyncRequestCounter--;
          }
        );
      }
    },
    getPrivacyTypeFromUrl() {
      if (this.$route.query.privacy && this.$route.query.privacy != 'null') {
        this.asyncRequestCounter++;
        AttendanceTypes.list().then(
          (types) => {
            let dt = types.data.data.attendanceTypes;
            let type = dt.filter((item) => {
              return item.id == parseInt(this.$route.query.privacy);
            });
            this.noSaveState = true;
            this.privacyType = {
              value: type[0].id,
              label: type[0].type.replace(' ', '&nbsp;'),
            };
            this.asyncRequestCounter--;
          },
          () => {
            this.asyncRequestCounter--;
          }
        );
      }
    },
    saveState(newState) {
      let urlObj = {
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        query: this.searchQuery,
        page: parseInt(newState.page),
        venueId: this.venueId ? this.venueId.id : null,
        // onlyOfficial: this.onlyOfficial,
        dateSpan:
          this.dateSpan && this.dateSpan.value ? this.dateSpan.value : null,
        order: newState.order,
        privacy:
          this.privacyType && this.privacyType.value
            ? this.privacyType.value
            : null,
        sortBy: newState.sortBy,
        type:
          this.visibleType && this.visibleType.value
            ? this.visibleType.value
            : null,
        listingType: this.listingType,
        tagTeaching: this.tagTeaching
          ? this.tagTeaching.map((i) => i.code).join(',')
          : null,
        tagLanguage: this.tagLanguage
          ? this.tagLanguage.map((i) => i.code).join(',')
          : null,
        enumVenue: this.enumVenue,
        enumDate: this.enumDate,
        enumDateSelected: this.enumDateSelected,
        show: this.show,
      };

      let url =
        window.location.pathname +
        '?' +
        Object.keys(urlObj)
          .filter((key) => urlObj[key])
          .map((key) => {
            return (
              encodeURIComponent(key) + '=' + encodeURIComponent(urlObj[key])
            );
          })
          .join('&');

      if (this.lastUrl === url) return false;
      this.lastUrl = url;

      if (this.noSaveState) {
        this.noSaveState = false;
        return true;
      }
      history.pushState(null, null, url);
      return true;
    },

    loadEvents(props) {
      this.pagination.descending = props.pagination.descending;
      this.pagination.sortBy = props.pagination.sortBy;
      if (!this.$store.state || !this.$store.state.profile.id) {
        if (this.noProfileRetryTimeout) clearTimeout(this.noProfileRetryTimeout);
        
        this.noProfileRetryTimeout = setTimeout(() => {
          this.loadEvents(props);
        }, 300)
        return;
      }


      const stateSaved = this.saveState({
        page: props.pagination.page,
        order: props.pagination.descending ? 'desc' : 'asc',
        sortBy: props.pagination.sortBy,
      });
      if (!stateSaved && !props.random) return;

      EventBus.$emit('onListingUrlChange', window.location.search);

      this.inProgress = true;
      let df, dt, pastFromDate, toDate, venueSelected, privacy, showCancelled;

      if (this.dateFrom) {
        df = moment(this.dateFrom, 'YYYY/MM/DD');
        if (df.isValid()) pastFromDate = df.startOf('day').format();
      }

      if (this.dateTo) {
        dt = moment(this.dateTo, 'YYYY/MM/DD');
        if (dt.isValid()) toDate = dt.endOf('day').format();
      }

      if (this.venueId && this.venueId.id) {
        venueSelected = this.venueId.id;
      }

      if (this.privacyType && this.privacyType.value) {
        privacy = this.privacyType.value;
      }

      if (this.visibleType && this.visibleType.value) {
        showCancelled = this.visibleType.value;
      }

      let searchOptions = {
        profile_id: this.$store.state.profile.id,
        query: this.searchQuery,
        from_date: pastFromDate,
        to_date: toDate,
        only_venue: venueSelected,
        only_official: this.onlyOfficial,
        order: props.pagination.descending ? 'desc' : 'asc',
        sortBy: props.pagination.sortBy || 'dateFrom',
        privacy: privacy,
        showCancelled: showCancelled,
        page: props.pagination.page,
        limit: (this.show === 'table' ? props.pagination.rowsPerPage : 500), 
        languages: this.tagLanguage
          ? this.tagLanguage.map((i) => (i ? i.code : null))
          : null,
        teachings: this.tagTeaching
          ? this.tagTeaching.map((i) => (i ? i.code : null))
          : null,
      };
      let action = Events.listCreated;
      
      if (this.listingType === 'all') {
        searchOptions.profile_id = null;
      }

      if (this.listingType === 'going') {
        action = Events.listGoing;

        let dateSuggested = null;
        let dateSelected = null;

        if (this.enumDateSelected) {
          dateSuggested = moment(this.enumDateSelected, 'YYYY/MM/DD');
          if (dateSuggested.isValid())
            dateSelected = dateSuggested.startOf('day').format().split('T')[0];
        }

        searchOptions = {
          page: props.pagination.page,
          limit: (this.show === 'table' ? props.pagination.rowsPerPage : 500), 
          filterDate: this.enumDate,
          filterDateSelected: dateSelected,
          filterLocation: this.enumVenue,
          languages: this.tagLanguage.map((i) => (i ? i.code : null)),
          teachings: this.tagTeaching.map((i) => (i ? i.code : null)),
          query: this.searchQuery,
        };
      }
      if (this.listingType === 'suggested') {
        action = Events.listSuggested;

        let dateSuggested = null;
        let dateSelected = null;

        if (this.enumDateSelected) {
          dateSuggested = moment(this.enumDateSelected, 'YYYY/MM/DD');
          if (dateSuggested.isValid())
            dateSelected = dateSuggested.startOf('day').format().split('T')[0];
        }

        searchOptions = {
          page: props.pagination.page,
          limit: (this.show === 'table' ? props.pagination.rowsPerPage : 500), 
          filterDate: this.enumDate,
          filterDateSelected: dateSelected,
          filterLocation: this.enumVenue,
          languages: this.tagLanguage.map((i) => (i ? i.code : null)),
          teachings: this.tagTeaching.map((i) => (i ? i.code : null)),
          query: this.searchQuery,
        };
      }

      this.$q.loading.show();

      action(searchOptions).then(
        (response) => {
          this.$q.loading.hide();

          this.eventList = response.data.data.getEvents || [];
          this.eventList.forEach((event) => {
            event.inProgress = moment().isBetween(event.date, event.dateTo);
            event.inPast = moment().diff(event.dateTo) > 0;
            event.inFuture = moment().diff(event.date) < 0;
            event.isMy =
              event.createdBy &&
              event.createdBy.id === this.$store.state.profile.id;
          });
          this.pagination.rowsNumber =
            response.data.count || this.eventList.length;
          // this.pagination.page = props.pagination.page;
          this.pagination.hasNextPage = response.hasNextPage;
          this.pagination.hasPrevPage = response.hasPrevPage;
          this.inProgress = false;
        },
        () => {
          this.inProgress = false;
          this.$q.loading.hide();
          console.log('error state, should go to login');
          // this.$router.push({ name: "login" });
        }
      );
    },
    searchEvents(random) {
      this.loadEvents({
        pagination: {
          sortBy: this.pagination.sortBy,
          descending: this.pagination.descending,
          page: this.pagination.page || 1,
          rowsPerPage: DEFAULT_ROWS_PER_PAGE,
          rowsNumber: 1,
        },
        random,
      });
    },
    deleteSelectedEvents() {
      this.showEventDeleteModal = true;
    },
    onDeleteModalClosed(confirmed) {
      let that = this;
      if (confirmed) {
        this.selected.forEach((selectedEvent) => {
          Events.delete(selectedEvent.id).then(() => {
            let eventToRemove = that.eventList.find(
              (ev) => ev.id == selectedEvent.id
            );
            eventToRemove.cancelled = true;
          });
        });
        this.selected = [];
        setTimeout(this.searchEvents, 1000);
      }
      this.showEventDeleteModal = false;
    },
    ensureDateFrom() {
      if (!this.dateFrom) this.dateFrom = moment().format('YYYY/MM/DD');
    },
    ensureDateTo() {},

    filterVenueFn(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        this.venueFilter = initialVenueFilter.filter(
          (v) => v.title && v.title.toLowerCase().indexOf(needle) > -1
        );
      });
    },

    filterPrivacyFn(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        this.privacyOptions = defaultPrivacyOptions.filter(
          (v) => v.title && v.title.toLowerCase().indexOf(needle) > -1
        );
      });
    },

    editEvent(eventId) {
      this.$router.push({ name: 'event-edit', params: { id: eventId } });
    },
    createEvent(eventId) {
      this.$router.push({ name: 'event-create', params: { id: eventId } });
    },
    async previewEvent(event) {
      this.showEventDetailModal = true;
      this.eventDetail = event;

      let detail = await Events.getSingle(event.id);
      if (!detail.data.data.getEvent) throw new Error('No preview data');

      if (
        detail.data.data.getEvent.venue &&
        detail.data.data.getEvent.venue.location
      ) {
        let loc = detail.data.data.getEvent.venue.location;
        let arr = loc.match(/\(([-0-9.]+) ([-0-9.]+)\)/);
        if (arr && arr.length >= 3)
          detail.data.data.getEvent.venue.locationLatLon =
            arr[2] + ', ' + arr[1];
      }
      this.fetchedEventDetail = detail.data.data.getEvent;
    },
    updateSelected(selected) {
      this.selected = selected;
    },
    updatePagination(pagination) {
      this.pagination = pagination;
    },
    gotoAllEvents() {
      this.$router.push({ name: 'all-events' });
    },
    gotoMyEvents() {
      this.$router.push({ name: 'events' });
    },

    handleCalendarPage(dates) {
      this.dateSpan = this.dateSpanOptions[this.dateSpanOptions.length - 1]; // last option
      this.dateFrom = dates.firstDate; 
      this.dateTo = dates.lastDate; 
      this.loadEvents({ pagination: { 
        sortBy: 'dateFrom', page: 1, descending: false, rowsPerPage: CALENDAR_LIMIT }
      })
    },

    loadAttendanceTypes() {
      AttendanceTypes.list().then((res) => {
        let dt = res.data.data.attendanceTypes;
        let types = [
          ...dt.map((item) => {
            return {
              label: item.type.replace(' ', '&nbsp;'),
              value: item.id,
            };
          }),
        ];
        this.privacyOptions = types;
      });
    },
    loadVenuesForUser() {
      if (!this.$store.state.profile.id) return;
      Venues.searchForUser(this.$store.state.profile.id).then((res) => {
        let dt = res.data.data.getVenuesOfUserEvents;
        initialVenueFilter = dt.map((item) => {
          if (item && item.title)
            item.label =
              item.title +
              (item.short_location ? ', ' + item.short_location : '');
          return item;
        });
        this.venueFilter = initialVenueFilter;
      });
    },
    setTagTeaching(tags) {
      this.tagTeaching = tags;
    },
    setTagLanguage(tags) {
      this.tagLanguage = tags;
    },
    setEnumVenue(val) {
      this.enumVenue = val;
    },
    setEnumDate(val) {
      this.enumDate = val;
    },

    startup() {
      myEventFilter.setCurrentState({
        ...this.$route.params,
        ...this.$route.query,
      });

      this.getPrivacyTypeFromUrl();

      this.getVenueFromUrl();
      this.getTagsFromUrl();

      this.pagination.descending =
        myEventFilter.getOrderBy() == 'desc' ? true : false;
      this.pagination.sortBy = myEventFilter.getSortBy();
      this.visibleType = myEventFilter.getVisibleType(
        defaultVisibleTypeOptions
      );
      this.listingType = myEventFilter.getListingType();
      this.onlyOfficial = myEventFilter.getOfficial();
      this.dateSpan = myEventFilter.getDateSpan(defaultDateSpanOptions);
      this.searchQuery = myEventFilter.getQuery();
      this.enumVenue = myEventFilter.getEnumVenue();
      this.enumDate = myEventFilter.getEnumDate();
      this.enumDateSelected = myEventFilter.getEnumDateSelected();
      this.pagination.page = myEventFilter.getPageNumber();

      if (this.dateSpan && this.dateSpan.value === 'custom') {
        this.dateFrom = myEventFilter.getDateFrom();
        this.dateTo = myEventFilter.getDateTo();
      }

      this.show = myEventFilter.getShow() || 'table'

      this.loadAttendanceTypes();
      this.loadVenuesForUser();

      startupInterval = setInterval(() => {
        if (this.asyncRequestCounter == 0) {
          clearInterval(startupInterval);
          this.watchersEnabled = true;
          setTimeout(this.searchEvents);
        }
      }, 100);
    },
    openExportEventsModal() {
      if (this.selected.length) {
        return (this.showExportEventsModal = true);
      }
      return null;
    },
    closeExportEventsModal() {
      this.showExportEventsModal = false;
    },

    // Return true if an array contains at least one event that a different user created.
    isNotMy() {
      return this.selected.some((item) => item.isMy === false);
    },
  },
  watch: {
    listingType(newval, oldval) {
      if (!this.watchersEnabled) return;
      this.pagination.page = 1;
      if (!newval || newval == oldval) return;

      // clear page variabled
      this.searchQuery = null;
      this.enumDateSelected = null;
      this.enumDate = null;
      this.enumVenue = null;
      
      const GOING_OR_SUGGESTED = ['going', 'suggested'].includes(newval);
      const ALL = ['all'].includes(newval);
      const CREATED = ['created'].includes(newval);

      if (GOING_OR_SUGGESTED) {
        this.dateFrom = null;
        this.dateTo = null;
        this.dateSpan = null;
        this.privacyType = null;
        this.visibleType = null;
        this.order = 'asc';
        this.pagination.descending = false;

      }
      if (ALL) {
        this.order = 'asc';
        this.pagination.descending = false;
        if (this.show === 'table') this.dateFrom = moment().format('YYYY/MM/DD')
      }
      
      if (CREATED) {
        this.order = 'desc';
        this.pagination.descending = true;

        if (this.show == 'table') { 
          this.dateSpan = defaultDateSpanOptions[0]; 
        }
        this.privacyType = privacyTypeAll;
        this.visibleType = defaultVisibleTypeOptions[0];
      }
      
      this.tagLanguage = [];
      this.tagTeaching = [];
      setTimeout(this.searchEvents);
    },
    tagLanguage() {
      if (!this.watchersEnabled) return;
      this.pagination.page = 1;
      setTimeout(this.searchEvents);
    },
    tagTeaching() {
      if (!this.watchersEnabled) return;
      this.pagination.page = 1;
      setTimeout(this.searchEvents);
    },
    searchQuery() {
      if (!this.watchersEnabled) return;
      this.pagination.page = 1;
      setTimeout(this.searchEvents);
    },
    venueId(val) {
      if (!this.watchersEnabled) return;
      if (!val || (val && val.id)) {
        this.pagination.page = 1;
        setTimeout(this.searchEvents);
      }
    },
    privacyType() {
      if (!this.watchersEnabled) return;
      this.pagination.page = 1;
      setTimeout(this.searchEvents);
    },
    visibleType() {
      if (!this.watchersEnabled) return;
      this.pagination.page = 1;
      setTimeout(this.searchEvents);
    },
    enumVenue() {
      if (!this.watchersEnabled) return;
      this.pagination.page = 1;
      setTimeout(this.searchEvents);
    },
    enumDate() {
      if (!this.watchersEnabled) return;
      this.pagination.page = 1;
      setTimeout(this.searchEvents);
    },
    enumDateSelected() {
      if (!this.watchersEnabled) return;
      this.pagination.page = 1;
      setTimeout(this.searchEvents);
    },
    dateSpan(val) {
      if (!val) return;
      if (val.value !== 'custom') this.customDateFieldsVisible = false;

      if (val.value === 'all') {
        this.dateFrom = moment()
          .subtract(2, 'year')
          .startOf('day')
          .format('YYYY/MM/DD');
        this.dateTo = moment()
          .add(2, 'year')
          .startOf('day')
          .format('YYYY/MM/DD');
      }
      if (val.value === 'today') {
        this.dateFrom = moment().startOf('day').format('YYYY/MM/DD');
        this.dateTo = moment().endOf('day').format('YYYY/MM/DD');
      }
      if (val.value === 'last-week') {
        this.dateFrom = moment()
          .subtract(8, 'day')
          .startOf('day')
          .format('YYYY/MM/DD');
        this.dateTo = moment()
          .subtract(1, 'day')
          .endOf('day')
          .format('YYYY/MM/DD');
      }
      if (val.value === 'last-month') {
        this.dateFrom = moment()
          .subtract(30, 'day')
          .startOf('day')
          .format('YYYY/MM/DD');
        this.dateTo = moment().endOf('day').format('YYYY/MM/DD');
      }
      if (val.value === 'next-week') {
        this.dateFrom = moment().startOf('day').format('YYYY/MM/DD');
        this.dateTo = moment().add(7, 'day').endOf('day').format('YYYY/MM/DD');
      }
      if (val.value === 'next-year') {
        this.dateFrom = moment().startOf('day').format('YYYY/MM/DD');
        this.dateTo = moment().endOf('year').format('YYYY/MM/DD');
      }
      if (val.value === 'custom') {
        this.customDateFieldsVisible = true;
        if (!this.initialLoadingState) {
          // this.dateFrom = moment().startOf('day').format('YYYY/MM/DD');
          // this.dateTo = moment().endOf('day').format('YYYY/MM/DD');
        }
      }
      if (this.watchersEnabled) {
        this.pagination.page = 1;
        return setTimeout(this.searchEvents);
      } else return false;
    },

    show(newval) {
      if (!this.watchersEnabled) return;
      this.eventList = [];
      if (newval === 'calendar') {
        this.dateFrom = moment().startOf('month').format('YYYY/MM/DD');
        this.dateTo = moment().endOf('month').format('YYYY/MM/DD');
        return;
      } else {
        // const GOING_OR_SUGGESTED = ['going', 'suggested'].includes(this.listingType);
        // const ALL = ['all'].includes(this.listingType);
        // const CREATED = ['created'].includes(this.listingType);

        // if (GOING_OR_SUGGESTED) {
        // }
        // if (ALL) {
        // }
        // if (CREATED) {
        // }
      }
      this.$nextTick(this.searchEvents);
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.fromRecurring == true) {
      this.showRecurringDelayWarning = true;
      setTimeout(() => {
        this.showRecurringDelayWarning = false;
      }, 1000 * 60 * 3); // 3 minutes
    }

    this.startup(false);

    window.onpopstate = () => {
      this.noSaveState = true;
      this.startup();
    };

    EventBus.$on('loadEvents', this.searchEvents);

    setTimeout(() => {
      this.initialLoadingState = false;
    }, 500);
  },
  destroyed() {
    EventBus.$off('loadEvents');
  },
};
