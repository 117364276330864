import { render, staticRenderFns } from "./EventList.vue?vue&type=template&id=0dcb57f1&"
import script from "./EventList.vue?vue&type=script&lang=js&"
export * from "./EventList.vue?vue&type=script&lang=js&"
import style0 from "./EventList.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QPage,QItem,QBtn,QTabs,QTab,QBtnToggle,QInput,QIcon,QSelect,QItemSection,QSlideTransition,QPopupProxy,QDate,QBanner,QDialog,QCard,QCardSection,QItemLabel,QCardActions,QSpace,QField,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QPage,QItem,QBtn,QTabs,QTab,QBtnToggle,QInput,QIcon,QSelect,QItemSection,QSlideTransition,QPopupProxy,QDate,QBanner,QDialog,QCard,QCardSection,QItemLabel,QCardActions,QSpace,QField})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
