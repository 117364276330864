import * as moment from 'moment-timezone';

const MOMENT_FORMAT = 'YYYY/MM/DD';

export const eventFilter = () => {
  let searchParams = null;

  function getParam(param) {
    if (!searchParams) return null;
    if (searchParams.has(param)) {
      return searchParams.get(param);
    } else return null;
  }

  function getMomentParam(param) {
    const parsedParam = getParam(param);
    if (parsedParam && parsedParam.toLowerCase() != 'null') {
      return moment(parsedParam, MOMENT_FORMAT).format(MOMENT_FORMAT);
    } else return false;
  }

  function getNow() {
    return moment().format(MOMENT_FORMAT);
  }

  function getNextYear() {
    return moment().add(12, 'months').format(MOMENT_FORMAT);
  }

  return {
    setCurrentState: (routeParams) => {
      searchParams = new URLSearchParams(routeParams);
    },
    getDateFrom: () => {
      return getMomentParam('dateFrom') || getNow();
    },
    getDateTo: () => {
      return getMomentParam('dateTo') || getNextYear();
    },
    getPageNumber: () => {
      return parseInt(getParam('page')) || 1;
    },
    getDateSpan: (options) => {
      let ds = getParam('dateSpan');
      return ds ? options.filter((i) => i.value == ds)[0] : options[0];
    },
    getQuery: () => {
      let q = getParam('query');
      if (!q || q.toLowerCase() === 'null') return '';
      else return q;
    },
    getOrderBy() {
      // :(( pollution
      const listingType = this.getListingType();
      const GOING_OR_SUGGESTED_OR_ALL = ['all', 'going', 'suggested'].includes(listingType);
      let defaultOrder = 'desc';
      if (GOING_OR_SUGGESTED_OR_ALL) defaultOrder = 'asc';
      return defaultOrder;
    },
    getSortBy: () => {
      return getParam('sortBy') || 'dateFrom';
    },
    getVisibleType: (options) => {
      let vst = getParam('type');
      return vst ? options.filter((i) => i.value == vst)[0] : null;
    },
    getVenueId: () => {
      return getParam('venueId');
    },
    getListingType: () => {
      return getParam('listingType') || 'created';
    },
    getOfficial: () => {
      return getParam('onlyOfficial') || false;
    },
    getEnumVenue: () => {
      return getParam('enumVenue');
    },
    getEnumDate: () => {
      return getParam('enumDate');
    },
    getEnumDateSelected: () => {
      return getParam('enumDateSelected');
    },
    getShow: () => {
      return getParam('show');
    },
  };
};
